import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import About from '../components/Screens/About';

// ====

const AboutPage = (props) => {
  const { data } = props;

  let doc;
  if (data) {
    doc = JSON.parse(data.index.content);
  }

  return (
    <Layout meta={doc.meta}>
      <About title={doc.title} copy={doc.body} image={doc.image} />
    </Layout>
  );
};

export default AboutPage;

// ====

export const QUERY = graphql`
  query AboutDefaultQuery {
    index: storyblokEntry(slug: { eq: "about" }, lang: { eq: "default" }) {
      content
    }
  }
`;
